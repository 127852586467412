import React, { useRef, useState } from "react";
import { pageTitle } from "../PageTitle";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import BreadCrumb from "../BreadCrumb";
import { Link } from "react-router-dom";
import contact from "../../assets/images/resource/contact.jpg";
import map from "../../assets/images/resource/map.jpg";
import toast from "react-hot-toast";

const Contact = () => {
  pageTitle("Contact");
  const handleSubmitOne = (event) => {
    event.preventDefault();
  };
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  // Handle form field changes.
  const handleChange = (e) => {
    // Extract the field name and value from the event.
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  // Validate the form on submission.

  const handleSubmit = (e) => {
    // Prevent the default page reload.
    e.preventDefault();

    fetch(
      "https://discord.com/api/webhooks/1284074157205622836/VfPt3-KyHmYAYGxq8ZuAJaEtciOtwOS54Js5FpXu6jAaX1fbTLW-S2Yhfu_Axf36hysu",
      {
        body: JSON.stringify({
          content:
            "Name:" +
            form.name +
            "\n" +
            "Email:" +
            form.email +
            "\n" +
            "Subject:" +
            form.subject +
            "\n" +
            "Phone:" +
            form.phone +
            "\n" +
            "Message:" +
            "\n" +
            form.message,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    )
      .then(function (res) {
        console.log(res);
        alert("Successfully submitted");
        // toast.success("Successfully submitted");
      })
      .catch(function (res) {
        console.log(res);
      });
  };

  return (
    <>
      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="contact__page p_relative see__pad">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-ms-12">
              <div className="contact__left">
                <figure>
                  <img src={contact} alt="" />
                </figure>
                <h4>Contact Information</h4>
                <div className="contact__info">
                  {/* <div className="contact__block">
                                    <div className="icon">
                                        <i className="icon-19"></i>
                                    </div>
                                    <div className="contact__text">
                                        <Link to="tell:3025550107">+44 7908 604988</Link> <br/>
                                    </div>
                                </div> */}
                  <div className="contact__block">
                    <div className="icon two">
                      <i className="icon-20"></i>
                    </div>
                    <div className="contact__text">
                      <Link to="mailto:admin@greenoak.tech">
                        admin@greenoak.tech
                      </Link>{" "}
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="contact__right">
                <div className="form__title">
                  <div className="title__data">
                    <div className="sub__title">
                      <h4>Contact Us</h4>
                    </div>
                    <div className="title">
                      <h2>Get a Free Quote</h2>
                    </div>
                  </div>
                </div>
                <div className="form-inner">
                  <form onSubmit={handleSubmit}>
                    <div className="row clearfix">
                      <div className="col-xl-6 form-group">
                        <input
                          type="name"
                          name="name"
                          placeholder="Your Name *"
                          value={form.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-xl-6 col-lg-12 form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Your Email *"
                          value={form.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-xl-6 col-lg-12 form-group">
                        <input
                          type="text"
                          name="subject"
                          placeholder="Your Subject "
                          value={form.subject}
                          onChange={handleChange}
                          required=""
                        />
                      </div>
                      <div className="col-xl-6 col-lg-12 form-group">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Your Phone "
                          value={form.phone}
                          onChange={handleChange}
                          required=""
                        />
                      </div>
                      <div className="ccol-xl-6 col-lg-12 form-group">
                        <textarea
                          name="message"
                          placeholder="Message"
                          value={form.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                      <div className="col-xl-12 form-group message-btn btn-box">
                        <button
                          className="theme-btn theme-btn-one"
                          type="submit"
                          name="submit-form"
                        >
                          Send your Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="google__map">
            <figure>
                <img src={map} alt="" />
            </figure>
        </div> */}
      <FooterOne></FooterOne>
    </>
  );
};

export default Contact;
