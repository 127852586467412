import React from 'react';
import { Link } from 'react-router-dom';
import shape4 from "../../../assets/images/shapes/shape-09.png";
import shape5 from "../../../assets/images/shapes/shape-10.png";
import { FaHome } from 'react-icons/fa';


const Service = () => {
    const servicesData = [
      {
        id: 1,
        title: 'Blockchain Development',
        number:'01',
        description: 'We specialize in building cutting-edge cryptocurrency, decentralized applications, and smart contract solutions that harness the power of blockchain technology.',
        icon: 'icon-04',
        href: '/services-details',
        data_aos_delay:'00ms',
        buttontext:'Read More',
      },
      {
        id: 2,
        title: 'Artificial Intelligence and Machine Learning',
        number:'02',
        description: 'Our AI and machine learning expertise enables us to develop intelligent systems that leverage advanced techniques in natural language processing, computer vision, and predictive analytics.',
        icon: 'icon-08',
        href: '/services-details',
        data_aos_delay:'200ms',
        buttontext:'Read More',
      },
      {
        id: 3,
        title: 'Web and Mobile Development',
        number:'03',
        description: 'From full-stack web applications to cross-platform mobile apps, we create responsive and user-friendly digital experiences that engage and delight users.',
        icon: 'icon-12',
        href: '/services-details',
        data_aos_delay:'400ms',
        buttontext:'Read More',
      },
      {
        id: 4,
        title: 'Enterprise Software Development',
        number:'04',
        description: 'Our enterprise software solutions streamline business processes, optimize resource management, and enhance customer relationships through powerful ERP, CRM, and supply chain systems.',
        icon: 'icon-12',
        href: '/services-details',
        data_aos_delay:'600ms',
        buttontext:'Read More',
      },
    ];
    return (
        <section className="service__two p_relative">
            <div className="pattern-layer">
                <div className=" pattern-1 p_absolute" data-parallax='{"x": 100}' style={{ backgroundImage: `url(${shape4})` }}></div>
            </div>
            <div className="row">
                {servicesData.map((item, id)=>(
                    <div className="col-xl-3 col-lg-6 col-md-12 wow fadeInUp animated" data-wow-delay={item.data_aos_delay} data-wow-duration="1500ms" key={id}>
                        <div className="service__block__two p_relative">
                            <div className="service__image" style={{ backgroundImage: `url(${shape5})` }}>
                                
                            </div>
                            <div className="service__icon">
                                <div className="service__number">
                                    <h1>{item.number}</h1>
                                </div>
                                {/* <i className={item.icon}></i> */}
                            </div>
                            <div className="service__text" style={{paddingTop:30}}>
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                            </div>
                            {/* <div className="service__button__two">
                                <Link to={item.href}>{item.buttontext}</Link>
                            </div> */}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Service;