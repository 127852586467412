import React, {useEffect} from 'react';
import HeaderTwo from '../../Header/HeaderTwo';
import FooterTwo from '../../Footer/FooterTwo';
import Blog from '../../HomeOne/Blog/Blog';
import Banner from '../../HomeTwo/Banner/Banner';
import About from '../../HomeTwo/About/About';
import Service from '../../HomeTwo/Service/Service';
import Choose from '../../HomeTwo/Choose/Choose';
import Brand from '../../HomeTwo/Brand/Brand';
import Pricing from '../../HomeTwo/Pricing/Pricing';
import Accomplised from '../../HomeTwo/Accomplised/Accomplised';
import Portfolio from '../../HomeTwo/Portfolio/Portfolio';
import { pageTitle } from '../../PageTitle';
import { NavLink, useLocation } from 'react-router-dom';



const HomeOne = () => {
    pageTitle('Green Oak Labs Ltd');
    return (
        <>
            <div className="home__two">
                <HeaderTwo></HeaderTwo>
                <div id='home'>
                <Banner></Banner>
                </div>
                <div id='about'>
                <About></About>
                </div>
                <div id='services'>
                <Service></Service>
                </div>
                <Choose></Choose>
                <Brand></Brand>
                <Accomplised></Accomplised>
                <div id='pricing'>
                <Pricing></Pricing>
                </div>
                {/* <Portfolio></Portfolio> */}
                {/* <Blog></Blog> */}
                <FooterTwo></FooterTwo>
            </div>
        </>
    );
};

export default HomeOne;